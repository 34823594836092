import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "開発環境に Docker を導入し開発を効率化する話〜開発本部・ TechLunch〜",
  "date": "2017-02-07T08:51:25.000Z",
  "slug": "entry/2017/02/07/175125",
  "tags": ["medley"],
  "hero": "./2017_02_07.png",
  "heroAlt": "docker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`口コミで探せる介護施設の検索サイト「介護のほんね」`}</a>{`を開発している新居です。 本日は、メドレー開発本部にて隔週で行われている勉強会（TechLunch）で、Docker の発表を行ったのでその一部を紹介したいと思います。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170131/20170131212540.png",
        "alt": "f:id:medley_inc:20170131212540p:plain",
        "title": "f:id:medley_inc:20170131212540p:plain"
      }}></img></p>
    <h1>{`メドレーと Docker`}</h1>
    <p>{`弊社では`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`オンライン病気事典 MEDLEY（メドレー）`}</a>{`の運用で Docker が使われています。 2015 年 2 月のリリース時から開発環境〜本番環境の全てで Docker が使われており、開発運用を通じてノウハウも少しずつ貯められています。`}</p>
    <p>{`一方、私が以前関わっていた「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の中で感じていた課題として、`}</p>
    <ul>
      <li parentName="ul">{`開発環境の構築で時間がとられる`}</li>
      <li parentName="ul">{`たまに`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Mac"
        }}>{`Mac`}</a>{`の OS（`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%AA%A5%DA%A5%EC%A1%BC%A5%C6%A5%A3%A5%F3%A5%B0%A5%B7%A5%B9%A5%C6%A5%E0"
        }}>{`オペレーティングシステム`}</a>{`）が新しいと、あるライブラリがインストールできなくなってしまう`}</li>
      <li parentName="ul">{`本番環境で使っている nginx の設定を弄りたいがテストしにくい`}</li>
    </ul>
    <p>{`などがありました。`}</p>
    <p>{`事業規模が大きくなっていくにつれエンジニア個々の時間の使い方も大事になっていきます。 頻度は少ないものの開発環境の構築で時間を無駄にしてしまったり、本番環境の稼働を止めてしまったりすることは事業に与える影響も大きくなってきます。`}</p>
    <p>{`こうした課題意識が、自分の勉強も踏まえて「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発環境を Docker 化してみるという取り組みに繋がりました。 さらにその勉強の中で得た知見を社内の勉強会でアウトプットすることで、また開発環境構築時にメンバーが Docker に触れる機会を作ることで技術の理解や底上げに繋がることを狙いました。`}</p>
    <h1>{`Docker とは`}</h1>
    <p>{`エンジニア界隈ではお馴染みの Docker ですが、このブログは医療従事者やエンジニア職ではない方もご覧になられていると思いますので、Docker の説明を簡単にしておきます。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Docker" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.docker.com%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.docker.com/">www.docker.com</a></cite>
    <p>{`Docker とは、ひとことでいうと「`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Linux"
      }}>{`Linux`}</a>{`上で動作するシンプルで使いやすい軽量コンテナ環境」です。 `}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Linux"
      }}>{`Linux`}</a>{`という OS 上にコンテナという箱を用意し、そのコンテナの中に必要なソフトウェアやライブラリ（ツール）などをインストールしてひとつのまとまりとしてパッケージングすることができます。`}</p>
    <p>{`これにより、`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%A6%A5%A7%A5%D6%A5%B5%A1%BC%A5%D3%A5%B9"
        }}>{`ウェブサービス`}</a>{`などが動作する環境をポータブルな環境にすることができ、開発環境や検証環境、本番環境でまったく同じ環境を作ることが容易になる`}</li>
      <li parentName="ul">{`これまでのような開発環境と本番環境の微妙な違いによる動作の不具合や、開発環境では動いていたのに本番にデプロイしたら動かなくなりましたといった問題から解放される`}</li>
      <li parentName="ul">{`エンジニアの精神的な不安も幾分か軽減される`}</li>
    </ul>
    <p>{`といったメリットを享受することができるようになります。`}</p>
    <p>{`また、新しいメンバーやディレクターの PC で Docker が動く状態になっていれば開発環境を構築するのも格段に楽になります。 PC の OS の違いなどによるライブラリがインストールできないといった問題で時間を奪われることもなくなるでしょう。`}</p>
    <p>{`このようにできるだけ簡単な言葉で Docker を説明してみましたが、Docker のようなコンテナ型仮想化技術というのは古くからあり、Docker を提供する Docker 社の FAQ ページではそれら（ここでは LXC）との違いが説明されています。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Docker Engine frequently asked questions (FAQ)" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fdocs.docker.com%2Fengine%2Ffaq%2F%23%2Fwhat-does-docker-technology-add-to-just-plain-lxc" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://docs.docker.com/engine/faq/#/what-does-docker-technology-add-to-just-plain-lxc">docs.docker.com</a></cite>
    <h1>{`勉強会の内容`}</h1>
    <p>{`冒頭でも述べましたが、弊社では MEDLEY の開発運用でのみ Docker を使っているため、自分も含め他サービスの運用メンバーで Docker に慣れていない人もいます（もちろん個人や前職で Docker を触っていたメンバーもいますが）。`}</p>
    <p>{`メンバーが増えてくるにつれ個々の技術レベルにも差が生まれてくるので、そういった差を埋めるためにも今回は Docker 初心者向けに基本的な話と既存の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Rails"
      }}>{`Rails`}</a>{`プロジェクトをどのように Docker 化していくかといった話をしました。`}</p>
    <p>{`実際の内容としては、`}</p>
    <ul>
      <li parentName="ul">{`Docker、コンテナとは？`}</li>
      <li parentName="ul">{`Docker for `}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Mac"
        }}>{`Mac`}</a>{`による Docker 環境の構築`}</li>
      <li parentName="ul">{`Docker Machine`}</li>
      <li parentName="ul">{`Docker Compose`}</li>
      <li parentName="ul">{`xhyve`}</li>
      <li parentName="ul">{`Kitematic`}</li>
      <li parentName="ul">{`コンテナの作り方`}</li>
      <li parentName="ul">{`Dockerfile の作り方`}</li>
      <li parentName="ul">{`Docker Compose による複数コンテナの管理`}</li>
      <li parentName="ul">{`ジョブメドレーの開発環境を Docker 化する話`}</li>
    </ul>
    <p>{`といった内容で発表を行いました。`}</p>
    <p>{`Docker for `}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Mac"
      }}>{`Mac`}</a>{`については 2016 年 7 月に正式版がリリースされ、それまでの Docker Toolbox による Docker 環境構築に比べて幾分か楽になり、Docker Toolbox からの移行も簡単でより Docker が使いやすい状況へと進みつつあります。周辺のエコシステムが積極的に整備されているのは Docker の利点のひとつでもあります。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Get started with Docker for Mac" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fdocs.docker.com%2Fdocker-for-mac%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://docs.docker.com/docker-for-mac/">docs.docker.com</a></cite>
    <p>{`また、Docker の内部でどういうツールが動いているのかを理解し、実際にコンテナを立てたり壊したりして試行錯誤を繰り返すことで基本的な使い方も体に染み付いていきます。`}</p>
    <p>{`ここまで来れば実際のサービスの開発環境を Dockerfile と docker-compose.yml などで定義し、ビルド・実行することで Docker を使った開発環境を作ることができます。
まだ途中になっている部分もありますが、これらの過程の中で得た知見などを中心に発表を行いました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`メドレー開発本部の技術勉強会（TechLunch）で発表した内容の一部を紹介しました。`}</p>
    <p>{`メドレーではこのような勉強会を通じてメンバーの技術の理解や底上げを促し、弊社が提供するサービスをより安全に効率良く開発・運用できるように努めています。
今回は開発環境を効率よく構築するためのツールとして Docker を取り上げ、それを開発本部内で勉強していきました。
これによって、開発本部のメンバーが開発しやすい環境を整えることを目指しました。
今後も新しくスタンダードになりつつある技術を積極的に勉強し、サービスをより良くする上で価値あるアウトプットにしていけるよう努めて行こうと思いました。`}</p>
    <p>{`メドレー開発本部で実施している「TechLunch」での発表内容は今後も定期的に紹介していくので、是非メドレーブログをチェックしてみてください！`}</p>
    <h2>{`参考`}</h2>
    <p>{`今回の勉強会ではこちらの本を参考にさせていただきました。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Docker 実戦活用ガイド | 吉岡 恒夫, paiza |本 | 通販 | Amazon" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2F4839959234" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.amazon.co.jp/dp/4839959234">www.amazon.co.jp</a></cite>
    <p>{`何冊か手にとって読んでみましたが、こちらの本が基本的なことから網羅的に書かれており、また細か過ぎず読むのにも時間がかからないので、初心者の最初の 1 冊としてオススメしたいと思います。`}</p>
    <h2>{`求人`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CLINICS のエンジニアリーダー田中さんに「聞いてみた」 by 平木 聡 |  メドレー平木の「気になるあの人に聞いてみた」" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley%2Fpost_articles%2F50054" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley/post_articles/50054">www.wantedly.com</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      